import { useContext, useEffect, useState } from "react";
import MSALApiClient from "./MSALApiClient";
import { AuthContext } from "@/context/AuthContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosFetch, axiosPost } from "./APIClient";
import { type AuthenticationResult } from "@azure/msal-browser";
import { env } from "@/env.mjs";
import {
  type Activity,
  type CallbotRecord,
  type Dispositions,
} from "@/types/callbotRecord";
import { type DashboardStats } from "@/types/dashboard";
import {
  type UserExecutionByUser,
  type UseDistributeInvoicesRequest,
  type UseUnassignedInvoicesRequest,
} from "@/types/user";
import { type Task } from "@/types/task";
import {
  type ActivityTypeParams,
  type ActivityTypeResponse,
  type InvoiceOpportunityDetails,
  type SaveInvoiceOpportunityResponse,
} from "@/types/callsApiClient";
import {
  type ActivityRecord,
  type ActivityType,
  type InvoiceOpportunity,
  type InvoiceOpportunityPayload,
} from "@/types/InvoiceOpportunity";
import { msalInstance } from "./msal";

export function useUnassignedTasks(
  from: string,
  to: string,
  suspense?: boolean,
  dateType?: string
) {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const getToken = async () => {
    return client.getMsalToken();
  };

  const fetchUnassignedTasks = async () => {
    const token = await getToken();
    const params = {
      url: env.NEXT_PUBLIC_BACKEND_URL + "/api/executions/find",
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
      data: {
        status: "READY_TO_REVIEW",
        from: from,
        to: to,
        filterBy: dateType,
      },
    };
    return axiosPost<CallbotRecord[]>(params);
  };

  const result = useQuery({
    queryKey: ["unassignedTasks", from, to, dateType],
    queryFn: fetchUnassignedTasks,
    suspense: suspense,
  });
  return result;
}

export function useAssignedTasks(
  from: string,
  to: string,
  suspense?: boolean,
  executionStatus?: string,
  dateType?: string
) {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const getToken = async () => {
    return client.getMsalToken();
  };

  const fetchAssignedTasks = async () => {
    const token = await getToken();
    const params = {
      url: env.NEXT_PUBLIC_BACKEND_URL + "/api/executions/find",
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
      data: {
        status: executionStatus || "READY_TO_REVIEW",
        from: from,
        to: to,
        filterBy: dateType,
      },
    };
    return axiosPost<CallbotRecord[]>(params);
  };

  const result = useQuery({
    queryKey: ["assignedTasks", from, to, executionStatus, dateType],
    queryFn: fetchAssignedTasks,
    suspense: suspense,
  });
  return result;
}

export function useInvoiceOpportunityDetails({
  invoiceOpportunityId,
  suspense = false,
}: InvoiceOpportunityDetails) {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const getToken = async () => {
    return client.getMsalToken();
  };

  const fetchInvoiceOpportunity = async () => {
    const token = await getToken();
    const params = {
      url:
        env.NEXT_PUBLIC_BACKEND_URL +
        "/api/executions/getDetail?id=" +
        invoiceOpportunityId,
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
    };
    return axiosFetch<{
      data: InvoiceOpportunity[];
    }>(params);
  };

  const result = useQuery({
    queryKey: ["invoiceOpportunity", invoiceOpportunityId],
    queryFn: fetchInvoiceOpportunity,
    suspense: suspense,
    retry: 3,
  });
  return result;
}

export function useActivityTypeList({ suspense = false }: ActivityTypeParams) {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const getToken = async () => {
    return client.getMsalToken();
  };

  const fetchActivityTypeList = async () => {
    const token = await getToken();
    const params = {
      url:
        env.NEXT_PUBLIC_BACKEND_URL +
        "/api/labels/find?labelGroupCode=activity_type",
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
    };
    const response = await axiosFetch<ActivityTypeResponse[]>(params);

    const { data } = response;
    return data && data.length && data[0] && data[0].labels
      ? (data[0].labels as ActivityType[])
      : [];
  };

  const result = useQuery({
    queryKey: ["activityTypeList"],
    queryFn: fetchActivityTypeList,
    suspense: suspense,
  });
  return result;
}

export function useMyTasks(
  from: string,
  to: string,
  suspense?: boolean,
  dateType?: string
) {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const getToken = async () => {
    return client.getMsalToken();
  };

  const fetchMyTasks = async () => {
    const token = await getToken();
    const params = {
      url: env.NEXT_PUBLIC_BACKEND_URL + "/api/users/executions/mine",
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
      data: {
        status: "READY_TO_REVIEW",
        from: from,
        to: to,
        filterBy: dateType,
      },
    };
    return axiosPost<Task[]>(params);
  };

  const result = useQuery({
    queryKey: ["myTasks", from, to, dateType],
    queryFn: fetchMyTasks,
    suspense: suspense,
  });
  return result;
}

export function useAvailableUsers(
  from?: string,
  to?: string,
  suspense?: boolean
) {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const getToken = async () => {
    return client.getMsalToken();
  };

  const fetchUsers = async () => {
    const token = await getToken();
    let queryString = "/api/users/available?";
    if (from) queryString += "&from=" + from;
    if (to) queryString += "&to=" + to;
    const params = {
      url: env.NEXT_PUBLIC_BACKEND_URL + queryString,
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
    };
    return axiosFetch<UserExecutionByUser[]>(params);
  };

  const result = useQuery({
    queryKey: ["useAvailableUsers", from, to],
    queryFn: fetchUsers,
    suspense: suspense,
  });

  return result;
}

export function useDashboardStats(
  from: string,
  to: string,
  suspense?: boolean
) {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const getToken = async () => {
    return client.getMsalToken();
  };

  const fetchDashboardStats = async () => {
    const token = await getToken();
    const params = {
      url:
        env.NEXT_PUBLIC_BACKEND_URL +
        "/api/stats/dashboard?from=" +
        from +
        "&to=" +
        to +
        "&section=all",
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
    };
    return axiosFetch<DashboardStats>(params);
  };

  const result = useQuery({
    queryKey: ["useDashboardStats", from, to],
    queryFn: fetchDashboardStats,
    suspense: suspense,
  });
  return result;
}

interface UseDistributeInvoicesProps {
  onError?: (error: unknown) => void;
  onSuccess?: () => void;
}

export function useDistributionMutation({
  onError,
  onSuccess,
}: UseDistributeInvoicesProps) {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const mutation = useMutation({
    mutationKey: ["distributeInvoices"],
    mutationFn: async (data: UseDistributeInvoicesRequest) => {
      const token = await client.getMsalToken();
      const params = {
        url: env.NEXT_PUBLIC_BACKEND_URL + "/api/executions/distribute",
        headers: {
          Authorization: "Bearer " + (token?.accessToken || ""),
        },
        data,
      };
      return axiosPost(params);
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });
  return mutation;
}

export function useUnassignMutation({
  onError,
  onSuccess,
}: UseDistributeInvoicesProps) {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const mutation = useMutation({
    mutationKey: ["unassignInvoices"],
    mutationFn: async (data: UseUnassignedInvoicesRequest) => {
      const token = await client.getMsalToken();
      const params = {
        url: env.NEXT_PUBLIC_BACKEND_URL + "/api/users/executions/unassign",
        headers: {
          Authorization: "Bearer " + (token?.accessToken || ""),
        },
        data,
      };
      return axiosPost(params);
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });
  return mutation;
}

export interface UseLogActivityMutationProps {
  id: number;
  activities: Activity[];
}
export function useLogActivityMutation() {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const mutation = useMutation({
    mutationKey: ["newLogActivity"],
    mutationFn: async (data: UseLogActivityMutationProps) => {
      const token = await client.getMsalToken();
      const params = {
        url: env.NEXT_PUBLIC_BACKEND_URL + "/api/users/executions/activities",
        headers: {
          Authorization: "Bearer " + (token?.accessToken || ""),
        },
        data,
      };
      return axiosPost(params);
    },
    onError: (error) => {
      throw error;
    },
  });
  return mutation;
}

export interface UseCompleteReviewMutationProps {
  record: {
    id: number;
    execution: {
      id: string;
    };
  };
  disposition: string;
}

export function useSaveInvoiceOpportunity() {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const mutation = useMutation({
    mutationKey: ["completeReviewTask"],
    mutationFn: async (data: InvoiceOpportunityPayload) => {
      const token = await client.getMsalToken();
      const params = {
        url: env.NEXT_PUBLIC_BACKEND_URL + "/api/executions/save",
        headers: {
          Authorization: "Bearer " + (token?.accessToken || ""),
        },
        data,
      };
      return axiosPost<SaveInvoiceOpportunityResponse>(params);
    },
    onError: (error) => {
      throw error;
    },
  });
  return mutation;
}

export function useDispositions(suspense?: boolean) {
  const client = new MSALApiClient(msalInstance, msalInstance.getAllAccounts());
  const getToken = async () => {
    return client.getMsalToken();
  };

  const fetchDispositions = async () => {
    const token = await getToken();
    const params = {
      url: env.NEXT_PUBLIC_BACKEND_URL + "/api/dispositions/get_all",
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
    };
    return axiosFetch<Dispositions[]>(params);
  };

  const result = useQuery({
    queryKey: ["useDispositions"],
    queryFn: fetchDispositions,
    suspense: suspense,
  });

  return result;
}
